<style lang="scss">
.businesses-grid__col {
  @include media-breakpoint-up(sm) {
    max-width: 320px;
  }
}
</style>

<template>
  <div class="pt-4">
    <div v-for="(business, i) in businesses" :key="business.id + '-' + i">
      <BusinessListItem class="pb-8" :business="business" :colored="i % 2 === 0" />
    </div>
  </div>
</template>

<script>
import BusinessListItem from "./business-item/BusinessListItem";
export default {
  components: {
    BusinessListItem,
  },
  props: {
    businesses: {
      type: Array,
      required: true,
    },
  },
};
</script>
