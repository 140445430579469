<style lang="scss">
</style>

<template>
  <v-row tag="section" id="business-offerings" justify="center">
    <v-col cols="12" class="mt-10 text-center">
      <h2 class="text-h5 font-weight-regular text-uppercase">Uudised ja artiklid</h2>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
      v-for="(post, i) in business.posts"
      :key="i"
      class="flex-grow d-flex justify-center"
    >
      <AlfaNewsPostItem :post="post" :height="250" small :show-chip="false" :show-date="false" />
    </v-col>
  </v-row>
</template>

<script>
import AlfaNewsPostItem from "@/blocks/news/components/news-post-item/AlfaNewsPostItem";
export default {
  components: {
    AlfaNewsPostItem,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
};
</script>
