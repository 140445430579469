<style lang="scss">
</style>

<template>
  <v-parallax
    :height="200"
    dark
    :src="'/storage/' + $store.getters.content('businesses.banner_background_image')"
  >
    <v-overlay absolute opacity=".6" color="#071b24">
      <v-row dense align="center" justify="center" class="white--text">
        <v-col
          class="text-center text-md-left"
          cols="12"
          md="9"
          v-html="
            $store.getters.content(
              'businesses.banner_content',
              `<h3 class='mb-1 text-h3 alternative-headings-font'>Pakud ehitusteenust?</h3><h4 class='subheading mr-3'>Liitu Ehitussektoriga ja tee end klientidele nähtavaks!</h4>`,
            )
          "
        ></v-col>
        <v-col cols="12" md="3" class="h-100 d-flex align-center justify-center">
          <base-btn
            :href="$store.getters.content('businesses.banner_href')"
            large
            color="white"
            outlined
          >
            {{ $store.getters.content("businesses.banner_cta") }}
          </base-btn>
        </v-col>
      </v-row>
    </v-overlay>
  </v-parallax>
</template>

<script>
export default {};
</script>
