<style lang="scss">
.business-contact-image {
  max-height: 250px;

  @include media-breakpoint-up(sm) {
    height: 100%;
    max-height: 352px;
  }
}
</style>

<template>
  <v-img :src="business.contact_image" class="business-contact-image elevation-1"></v-img>
</template>

<script>
export default {
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
};
</script>
