<template>
  <!--
    This post item requires prop "height."
    Has all data on top of image.
 -->
  <v-img
    v-bind="$attrs"
    :height="height"
    :src="post.image"
    class="bottom-gradient__wrap elevation-1"
  >
    <div class="bottom-gradient" />
    <router-link
      :to="{ name: 'NewsPost', params: { id: post.id, slug: post.slug  } }"
      class="bottom-gradient__content d-flex align-end white--text pa-3"
      :title="post.title"
    >
      <div>
        <v-chip v-if="showChip" small label color="black" dark class="pa-2 mb-2">
          {{ $store.getters["news/categoryOf"](post).title }}
        </v-chip>
        <div style="line-height: 2 !important">
          <v-clamp
            autoresize
            v-if="clamp"
            :class="`text-${small ? 'h6' : 'h3'}`"
            tag="h3"
            :max-lines="clampMaxLines"
          >
            {{ post.title }}
          </v-clamp>
          <h3 v-else :class="`text-${small ? 'h6' : 'h4'}`">{{ post.title }}</h3>
        </div>
        <div v-if="showDate">
          <v-icon x-small dark left>mdi-clock-outline</v-icon>
          <small>{{ post.date }}</small>
        </div>
      </div>
    </router-link>
  </v-img>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      required: true,
    },
    clamp: {
      type: Boolean,
      default: true,
    },
    clampMaxLines: {
      type: Number,
      default: 2,
    },
    showChip: {
      type: Boolean,
      default: true,
    },
    showDate: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
