<style lang="scss">
</style>

<template>
  <v-container>
    <v-row justify="space-around">
      <v-col
        v-for="category in categories"
        :key="category.slug"
        class="text-center"
        cols="12"
        sm="4"
        md="2"
        :title="`${category.title} kategooria | ${category.count} ettevõtet`"
      >
        <a
          href="#"
          class="link-secondary image-gradient__wrap"
          :class="{
            'link__active image-gradient__active': activeCategoryIds.includes(category.id),
          }"
          @click.prevent="$emit('input', category)"
        >
          <!-- {{ category.srcset }} -->
          <v-avatar
            :size="$vuetify.breakpoint.xl ? 140 : $vuetify.breakpoint.lg ? 115 : 100"
            class="elevation-gentle"
          >
            <v-img :aspect-ratio="1" :src="category.thumbnail">
              <div class="image-gradient" />
            </v-img>
          </v-avatar>
          <div class="mt-2 text-h6 text-center">
            {{ category.title }}
          </div>
        </a>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="showTrustmark">
      <v-col style="max-width: max-content">
        <v-img
          width="170px"
          src="/storage/base/trust-logo/ehitussektor-soovitab-2022.png"
          @click="$router.push($store.getters.setting('trustmark_path'))"
          class="cursor-pointer"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    showTrustmark: {
      type: Boolean,
      default: false,
    },
    activeCategoryIds: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    categories() {
      return this.$store.state.business.categories;
    },
  },
};
</script>
