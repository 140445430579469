<style lang="scss">
</style>

<template>
  <v-list dense>
    <v-list-item
      :title="`${category.title} kategooria | ${category.count} ettevõtet`"
      color="primary"
      @click.prevent="$emit('input', category)"
      :class="{ 'v-list-item--active': activeCategoryIds.includes(category.id) }"
      v-for="category in categories"
      :key="category.slug"
    >
      <v-list-item-action>
        <v-avatar>
          <v-img :src="category.avatar"></v-img>
        </v-avatar>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ category.title }}</v-list-item-title>
      </v-list-item-content>
    <!--<v-list-item-action>
        <small class="disabled--text">({{ category.count }})</small>
      </v-list-item-action> -->
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    activeCategoryIds: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    categories() {
      return this.$store.state.business.categories;
    },
  },
};
</script>
