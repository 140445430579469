<template>
  <base-card class="image-gradient__wrap" elevation="0">
    <v-img
      class="elevation-1"
      :max-height="$vuetify.breakpoint.xs ? null : '125px'"
      :aspect-ratio="16 / 10"
      :src="business.list_image"
    >
      <router-link
        :to="{ name: 'Business', params: { business_slug: business.slug } }"
        tag="div"
        class="image-gradient cursor-pointer"
        style="z-index: 1"
      />
      <div class="d-flex justify-space-between" style="z-index: 2">
        <div>
          <base-btn
            v-if="business.is_featured"
            x-small
            v-bind="{ tile: true, depressed: true, dark: true }"
            readonly
            :ripple="false"
            color="yellow darken-3 ma-1"
            tag="div"
          >
            <v-icon x-small class="mr-1">mdi-star</v-icon>
            Esiletõstetud
          </base-btn>
        </div>
        <div class="pt-1 pr-1 pl-5 pb-5 cursor-default" style="z-index: 2">
          <v-avatar color="rgba(0,0,0,.5)" size="30">
            <BookmarkBusiness :business="business" color="white" />
          </v-avatar>
        </div>
      </div>
      <v-avatar
        @click="$router.push({ name: 'Business', params: { business_slug: business.slug } })"
        class="position-absolute"
        style="bottom: 6px; left: 6px; z-index: 2"
        color="white"
        size="60"
      >
        <v-sheet color="white">
          <v-img max-width="50px" max-height="50px" contain :src="business.logo_avatar"></v-img>
        </v-sheet>
      </v-avatar>
      <!-- <base-card
        @click="$router.push({ name: 'Business', params: { business_slug: business.slug } })"
        class="position-absolute"
        style="bottom: 6px; left: 6px; z-index: 2"
        rounded
      >
        <v-img max-width="70px" max-height="50px" contain :src="business.logo_avatar"></v-img>
      </base-card> -->
    </v-img>

    <v-sheet class="mt-2">
      <router-link
        class="link"
        :to="{ name: 'Business', params: { business_slug: business.slug } }"
      >
        <h4 class="text-h6">
          {{ business.title }}
        </h4>
      </router-link>
      <div v-if="business.tags">
        <v-clamp autoresize :max-lines="1" tag="small" class="text--disabled">
          {{ business.tags.map((el) => el.name).join(", ") }}
        </v-clamp>
      </div>
      <div
        class="cursor-pointer"
        @click="$router.push({ name: 'Business', params: { business_slug: business.slug } })"
      >
        <v-clamp
          autoresize
          class="text-body-2 mt-1 mb-2"
          tag="p"
          :max-lines="4"
          style="max-width: 500px"
        >
          {{ business.excerpt }}
        </v-clamp>
      </div>
      <div class="d-flex justify-end">
        <v-img
          max-width="110px"
          max-height="50px"
          src="/storage/base/trust-logo/ehitussektor-soovitab-2022.png"
          contain
          @click="$router.push($store.getters.setting('trustmark_path'))"
          class="mt-auto mb-0 mr-1 cursor-pointer"
        ></v-img>
      </div>
    </v-sheet>
  </base-card>
</template>

<script>
import BookmarkBusiness from "../BookmarkBusiness";
export default {
  components: {
    BookmarkBusiness,
  },
  props: {
    colored: {
      type: Boolean,
      default: false,
    },
    business: {
      type: Object,
      required: true,
    },
  },
};
</script>
