<template>
  <ContactForm
    :url="url"
    :business="business"
    title="Saada päring"
    :asks-business-name="false"
    :can-attach-files="true"
  />
</template>

<script>
import ContactForm from "@/blocks/global/components/ContactForm";
export default {
  components: {
    ContactForm,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      url: `business/${this.business.slug}/send-message`,
    };
  },
};
</script>
