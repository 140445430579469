<template>
  <section>
    <div class="d-md-flex align-center justify-space-between">
      <div>
        <div>
          <v-chip
            close
            v-if="query.search_term"
            key="search-term"
            @click:close="onSearchTermClosed()"
            :title="`Otsingusõna: ` + query.search_term"
            class="ma-1 text-capitalize"
          >
            {{ query.search_term }}
          </v-chip>
          <v-chip
            close
            v-for="category in selectedCategories"
            :key="'category-' + category.id"
            @click:close="onCategoryClosed(category)"
            class="ma-1 text-capitalize"
          >
            {{ category.title }}
          </v-chip>
          <v-chip
            close
            v-for="region in selectedRegions"
            :key="'region-' + region.slug"
            @click:close="onRegionClosed(region)"
            class="ma-1"
          >
            {{ region.title }}
          </v-chip>
          <h3 class="text-h5 font-weight-thin pb-2 pt-1 pb-md-0" v-html="constraintsDescription" />
        </div>
      </div>
      <div class="d-flex align-center justify-center justify-md-end flex-nowrap">
        <v-select
          style="max-width: 200px"
          class="mr-1"
          hide-details="auto"
          dense
          outlined
          :items="regions"
          label="Vali tegevuspiirkond"
          item-text="title"
          item-value="id"
          @input="onRegionSelected"
          :value="selectedRegions.length === 1 ? selectedRegions[0] : null"
        />

        <ToggleListGrid
          :is-grid="isGrid"
          @input="isGrid = $event"
          v-if="$vuetify.breakpoint.smAndUp"
        />
      </div>
    </div>

    <div>
      <BusinessesGrid v-if="isGrid || $vuetify.breakpoint.xs" :businesses="items" />
      <BusinessesList v-else :businesses="items" />

      <div class="text-center">
        <div
          class="pb-5"
          :title="allLoaded ? 'Rohkem ettevõtteid ei ole' : 'Lae rohkem ettevõtteid'"
        >
          <base-btn
            color="black"
            :dark="!allLoaded"
            :loading="loading"
            @click="fetchNextPageItems"
            :disabled="allLoaded"
          >
            <span v-if="!total">Ettevõtteid ei leitud</span>
            <span v-else-if="allLoaded">Rohkem ettevõtteid ei ole</span>
            <span v-else>Lae rohkem ettevõtteid</span>
          </base-btn>

          <div
            class="pt-3 text-body-2 text--disabled"
            v-if="query.page > 1 && total - items.length > 0"
          >
            <em>
              Laadida on veel {{ total - items.length }}
              {{ total - items.length > 1 ? "ettevõtet" : "ettevõte" }}
            </em>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BusinessesList from "./BusinessesList";
import BusinessesGrid from "./BusinessesGrid";
import ToggleListGrid from "./ToggleListGrid";

export default {
  components: {
    ToggleListGrid,
    BusinessesGrid,
    BusinessesList,
  },
  props: {
    storeState: {
      type: Object,
      required: true,
    },
    storeAccessor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isGrid: false,
    };
  },
  created() {
    this.fetchFirstPageItems(true);
  },
  computed: {
    constraintsDescription() {
      if (this.loading) {
        return "Palun oota..";
      } else if (!this.total) {
        return "Kriteeriumitele vastavaid ettevõtteid ei leitud.";
      }
      const one = this.total === 1;
      let text = ``;
      if (this.query.search_term) {
        text += `märksõnale <strong>${this.query.search_term}</strong> `;
      }

      const isConstrainedByCategory = this.selectedCategories && this.selectedCategories.length > 0;
      const isConstrainedByRegion = this.selectedRegions && this.selectedRegions.length > 0;

      text += `leiti ${this.total} `;
      text += one ? "ettevõte" : " ettevõtet";

      /* KATEGOORIAD */
      if (isConstrainedByCategory) {
        text += one ? ", mis kuulub " : ", mis kuuluvad ";
        const singular = this.selectedCategories.length === 1;
        text += singular ? "kategooriasse " : "kategooriatesse ";
        text += `"${this.$joinAsSentence(
          this.selectedCategories.map((el) => {
            return el.title;
          }),
          `", "`,
          `" ja "`,
        )}"`;
        if (isConstrainedByRegion) text += "<br> ja ";
      }

      /* REGIOONID */
      if (isConstrainedByRegion) {
        if (!isConstrainedByCategory) text += ", mis ";
        text += one ? "tegutseb " : "tegutsevad ";
        const singular = this.selectedRegions.length === 1;
        text += singular ? "piirkonnas " : "piirkondades ";
        text += `"${this.$joinAsSentence(
          this.selectedRegions.map((el) => {
            return el.title;
          }),
          `", "`,
          `" ja "`,
        )}"`;
      }

      text += ".";

      return this.$capitalize(text);
    },
    loading() {
      return this.storeState.loading;
    },
    total() {
      return this.storeState.total;
    },
    query() {
      return this.storeState.query;
    },
    items() {
      return this.storeState.items;
    },
    regions() {
      // must be explicitly from $store.state.business, not from `storeState` property
      return this.$store.state.business.regions;
    },
    categories() {
      // must be explicitly from $store.state.business, not from `storeState` property
      return this.$store.state.business.categories;
    },
    selectedCategories() {
      return this.categories.filter((el) => this.query.category_ids.includes(el.id));
    },
    selectedRegions() {
      return this.regions.filter((el) => this.query.region_ids.includes(el.id));
    },
    allLoaded() {
      if (this.total === null) return null;
      return this.items.length >= this.total;
    },
  },
  methods: {
    queryGet(field) {
      const str = this._.get(this.query, field, "").toLowerCase();
      if (!str) return null;
      return str.split(",");
    },

    onSearchTermClosed(category) {
      this.$store.commit(`${this.storeAccessor}SET_QUERY`, {
        search_term: "",
      });
      this.fetchFirstPageItems();
    },

    onCategoryClosed(category) {
      this.$store.commit(`${this.storeAccessor}SET_QUERY`, {
        category_ids: this.query.category_ids.filter((id) => id !== category.id),
      });
      this.fetchFirstPageItems();
    },

    onRegionClosed(region) {
      this.$store.commit(`${this.storeAccessor}SET_QUERY`, {
        region_ids: this.query.region_ids.filter((id) => id !== region.id),
      });
      this.fetchFirstPageItems();
    },

    onRegionSelected(regionId) {
      this.$store.commit(`${this.storeAccessor}SET_QUERY`, { region_ids: [regionId] });
      this.fetchFirstPageItems();
    },

    fetchFirstPageItems(skipIfFetched = false) {
      this.$store.dispatch(`${this.storeAccessor}fetchFirstPageItems`, skipIfFetched);
    },

    fetchNextPageItems() {
      this.$store.dispatch(`${this.storeAccessor}fetchNextPageItems`);
    },
  },
};
</script>
