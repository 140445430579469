<style lang="scss">
// @import "@/dependencies.scss";
</style>

<template>
  <base-btn
    class="mt-1"
    :class="{ 'd-none': coordinates === '#' }"
    target="_blank"
    :href="url"
    v-bind="{ small: true, color: `black`, dark: true, elevation: 0, ...$attrs }"
    @click.prevent="onClick"
  >
    Näita teekonda
    <v-icon right>mdi-open-in-new</v-icon>
  </base-btn>
</template>

<script>
export default {
  props: {
    coordinates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      windowObjectReference: null,
      rand: Math.floor(Math.random() * 999 + 1),
    };
  },
  computed: {
    url() {
      if (!this.coordinates || this.coordinates.length < 2) return `#`;

      return `https://www.google.com/maps/dir//${this.coordinates.join(",")}?hl=et`;
    },
  },
  methods: {
    onClick() {
      if (!this.url === "#") return;
      if (this.windowObjectReference === null || this.windowObjectReference.closed) {
        this.windowObjectReference = window.open(
          this.url,
          "page_" + this.rand, // if page is refreshed then we have no access to old window so we open new.
          'toolbar=no, menubar=no, width=700, height=700, top=100, left=100"',
        );
      } else {
        this.windowObjectReference.focus();
      }
    },
  },
};
</script>
