
<template>
  <v-menu offset-y nudge-bottom="2" tile :value="showMenu" :disabled="!showMenu">
    <template #activator="{ on }">
      <v-text-field
        v-on="on"
        class="search-text-field"
        flat
        solo
        placeholder="Sisesta märksõna (n. moodulmajad, sisetööd)"
        hide-details="auto"
        v-model="searchTerm"
      ></v-text-field>
    </template>
    <v-sheet tile style="max-height: 200px">
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :to="{ name: 'Business', params: { business_slug: item.slug } }"
        >
          <v-list-item-action>
            <v-avatar class="elevation-1" color="white" size="32">
              <v-img contain :src="item.logo_avatar"></v-img>
            </v-avatar>
          </v-list-item-action>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>

        <!-- Not found -->
        <v-list-item key="not-found" v-show="notFoundMessage">
          <v-list-item-title>
            <span class="text-body-2" v-html="notFoundMessage"></span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      searchTerm: this.value,
      lastInstantSearchTerm: this.value,
      lastInstantSearchTermResults: [],
      items: [],
      fetchingSearchTerms: [],

      /**
       * typingIndex is used to detect if user is currently typing.
       * If user has finished typing, then it's time to fetch instant results.
       *
       * 0 means that user has finished typing,
       * anything above 0 means that user is still typing.
       *
       * */
      typingIndex: 0,

      /**
       * After the timeout has passed since last input,
       * typing index will be set to 0
       */
      instantSearchTimeout: 500,

      /**
       * To disable/enable instant search, use $refs method, eg:
       * disable: this.$refs.searchField.disableInstantSearch(); and
       * enable.$refs.searchField.enableInstantSearch();
       */
      instantSearchDisabled: false,
    };
  },

  computed: {
    showMenu() {
      if (!this.lastInstantSearchTerm || this.instantSearchDisabled || this.searchTerm.length < 2) {
        return false;
      }
      return true;
    },
    notFoundMessage() {
      if (
        this.fetchingInstantResults ||
        !this.showMenu ||
        this.items.length > 0 ||
        !this.lastInstantSearchTerm ||
        this.lastInstantSearchTermResults.length > 0
      ) {
        return null;
      }
      return `Tulemusi märksõnale <strong>${
        this.lastInstantSearchTerm ? his.lastInstantSearchTerm : ""
      }</strong> ei leitud.`;
    },
    isTyping() {
      return this.typingIndex > 0;
    },
    fetchingInstantResults() {
      return this.fetchingSearchTerms.includes(this.searchTerm);
    },
  },

  watch: {
    value(value) {
      this.searchTerm = value;
    },
    searchTerm(searchTerm) {
      if (searchTerm) {
        this.setIsTyping();
      } else {
        this.items = [];
      }
      if (searchTerm === this.value) return;
      this.$emit("input", searchTerm);
    },
    isTyping(isTyping) {
      if (isTyping) {
        return;
      }
      this.fetchInstantResults();
    },
  },

  methods: {
    async fetchInstantResults() {
      if (this.instantSearchDisabled) return;
      this.fetchingSearchTerms.push(this.searchTerm);
      const searchedTerm = this.searchTerm;
      try {
        const response = await this.axios.post("business/query/instant", {
          per_page: 10,
          search_term: this.searchTerm,
        });
        if (searchedTerm !== this.searchTerm) {
          return;
        }
        this.lastInstantSearchTerm = searchedTerm;
        this.items = response.data.data;
        this.lastInstantSearchTermResults = response.data.data;
      } finally {
        this.fetchingSearchTerms = this.fetchingSearchTerms.filter((term) => term !== searchedTerm);
      }
    },

    setIsTyping() {
      const typingIndex = this.typingIndex + 1;
      this.typingIndex = typingIndex;
      setTimeout(() => {
        if (this.typingIndex === typingIndex) this.typingIndex = 0;
      }, this.instantSearchTimeout);
    },

    disableInstantSearch() {
      this.instantSearchDisabled = true;
    },

    enableInstantSearch() {
      setTimeout(() => {
        this.instantSearchDisabled = false;
      }, this.instantSearchTimeout + 100);
    },
  },
};
</script>
