<template>
  <v-container class="businesses-banner" :key="isMobile ? 'mobile' : 'desktop'">
    <v-row>
      <v-col cols="12">
        <div class="position-relative">
          <!-- <v-sheet class="position-absolute" style="z-index: 50" v-if="isMobile">
            <BusinessesBannerSearch />
          </v-sheet> -->
          <div class="d-flex justify-center justify-md-space-around h-100">
            <div
              :style="!isMobile ? `margin-top: ${firstImageWidth / 2}px` : ''"
              class="mr-md-5 flex-shrink-1"
            >
              <BusinessesBannerSearch @searched="$emit('searched', $event)" />
            </div>

            <div class="d-flex justify-end flex-shrink-1" v-if="!isMobile">
              <base-card
                elevation="6"
                class="mx-3 mb-8"
                style="margin-top: 60px"
                :width="firstImageWidth"
                :height="firstImageHeight"
              >
                <v-img
                  :src="image('cover_image_left')"
                  :max-width="firstImageWidth"
                  :height="firstImageHeight"
                ></v-img>
              </base-card>

              <div>
                <!-- <div v-if="$vuetify.breakpoint.lgAndUp"> -->
                <base-card elevation="3" class="ml-3 mr-1">
                  <v-img
                    :src="image('cover_image_right')"
                    :max-width="secondImageWidth"
                    :height="secondImageHeight"
                  ></v-img>
                </base-card>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BusinessesBannerSearch from "./BusinessesBannerSearch";
export default {
  components: {
    BusinessesBannerSearch,
  },

  computed: {
    imageSizer() {
      if (this.$vuetify.breakpoint.width < 1020) return 0.6;
      if (this.$vuetify.breakpoint.md) return 0.7;
      return 0.85;
      //   if (this.$vuetify.breakpoint.lg) return 0.85;
      //   return 1;
    },
    firstImageWidth() {
      return 400 * this.imageSizer;
    },
    firstImageHeight() {
      return 500 * this.imageSizer;
    },
    secondImageWidth() {
      return this.firstImageWidth * 0.9;
    },
    secondImageHeight() {
      return this.firstImageHeight * 0.9;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    image(type) {
      return "/storage/" + this.$store.getters.content(['businesses', type]);
    },
  },
};
</script>
