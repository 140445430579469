<style lang="scss">
</style>

<template>
  <div>
    <BusinessCategoriesGrid v-bind="$attrs" v-on="$listeners" v-if="$vuetify.breakpoint.smAndUp" />
    <BusinessCategoriesList v-bind="$attrs" v-on="$listeners" v-else />
  </div>
</template>

<script>
import BusinessCategoriesList from "./BusinessCategoriesList";
import BusinessCategoriesGrid from "./BusinessCategoriesGrid";
export default {
  components: {
    BusinessCategoriesList,
    BusinessCategoriesGrid,
  },
};
</script>
