<style lang="scss">
.vel-img-title {
  background-color: black;
  padding: 6px 9px;
  color: #fff !important;
  font-size: 14px !important;
}
</style>

<template>
  <v-row>
    <v-col cols="12" class="mt-10 text-center">
      <h2 class="text-h5 font-weight-regular text-uppercase">Referentsid</h2>
      <vue-easy-lightbox
        :visible="lightboxVisible"
        :imgs="lightboxImages"
        :index="lightboxIndex"
        @hide="lightboxVisible = false"
      ></vue-easy-lightbox>
    </v-col>
    <v-col
      v-for="(item, i) in items"
      :key="item.id"
      class="d-flex child-flex"
      cols="6"
      sm="4"
      md="3"
      lg="2"
    >
      <v-img
        style="cursor: pointer"
        @click="showLightboxImage(i)"
        :src="item.thumbnail"
        aspect-ratio="1"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
    <v-col cols="12">
      <div class="text-center">
        <div class="pb-5" :title="allLoaded ? 'Rohkem referentse ei ole' : 'Lae rohkem referentse'">
          <base-btn
            color="black"
            :dark="!allLoaded"
            :loading="loading"
            @click="loadMore"
            :disabled="allLoaded"
          >
            Lae rohkem
          </base-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";
export default {
  components: {
    VueEasyLightbox,
  },
  mounted() {
    this.setLightboxItems();
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sliceTo: 12,
      loading: null,
      lightboxVisible: false,
      lightboxImages: null,
      lightboxIndex: null,
    };
  },
  computed: {
    items() {
      return this.business.references.slice(0, this.sliceTo);
    },
    allLoaded() {
      return this.items.length >= this.business.references.length;
    },
  },
  methods: {
    loadMore() {
      if (this.loading) return;
      this.loading = true;

      setTimeout(() => {
        this.sliceTo += 12;
        this.loading = false;
      }, 500);
    },
    setLightboxItems() {
      this.lightboxImages = this.business.references.map((el, i) => {
        return {
          title:
            (el.description ? el.description : this.business.title) +
            " | referentsi foto " +
            (i + 1),
          src: el.image,
        };
      });

      //   this.$imageViewer.images(
      //     this.business.references.map((el) => {
      //       return { name: this.business.title + " | referentsi foto ", url: el.large };
      //     }),
      //   );
    },
    showLightboxImage(index) {
      this.lightboxIndex = index; // index of imgList
      this.lightboxVisible = true;

      //   this.$imageViewer.index(index);
      //   this.$imageViewer.show();
    },
  },
};
</script>
