
<template>
  <div>
    <NotFound v-if="noQueryParams" />

    <v-container v-else class="h-100">
      <v-row no-gutters class="d-flex justify-center my-10">
        <v-col cols="12" sm="9" md="6">
          <div>
            <v-card tile class="px-5 pb-3 pt-5">
              <h1 class="text-h3 text-center mb-4">Kontaktiteate failide allalaadimine</h1>

              <v-card-text>
                <v-row justify="center">
                  <v-col cols="12" sm="9">
                    <div>
                      <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="text, sentences, text, text, paragraph"
                      ></v-skeleton-loader>
                      <div v-else-if="notFound">
                        <v-alert type="warning">
                          <div class="text-h4">Faile ei leitud.</div>
                          <p>
                            Tõenöoliselt on see kontaktiteade aegunud ning faile ei ole enam
                            võimalik alla laadida.
                          </p>
                        </v-alert>
                      </div>
                      <v-list v-else>
                        <v-list-item
                          key="all"
                          :href="`/attachments/download?key=` + $route.query.key"
                          target="_blank"
                          color
                          class="grey darken-4"
                          dark
                        >
                          <v-list-item-action><v-icon>mdi-folder-zip</v-icon></v-list-item-action>
                          <v-list-item-content>Lae kõik failid alla</v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-for="(item, i) in items"
                          :key="i"
                          :href="item.href"
                          target="_blank"
                        >
                          <v-list-item-action><v-icon>mdi-paperclip</v-icon></v-list-item-action>
                          <v-list-item-content>
                            {{ item.name }}
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NotFound from "../../not-found/views/NotFound.vue";
export default {
  components: {
    NotFound,
  },
  head() {
    return {
      title: "Kontaktiteate failide allalaadimine" + this.$store.getters.setting("meta_brand"),
    };
  },
  created() {
    if (this.$route.query.key) {
      this.fetch();
    } else {
      this.noQueryParams = true;
    }
  },
  data() {
    return {
      loading: false,
      items: [],
      notFound: null,
      noQueryParams: null,
    };
  },
  methods: {
    async fetch() {
      this.loading = true;
      try {
        const response = await this.axios.post("message-attachment/index", {
          key: this.$route.query.key,
        });
        if (
          response.data.items &&
          Array.isArray(response.data.items) &&
          response.data.items.length
        ) {
          this.items = response.data.items;
        } else {
          this.notFound = true;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
