<template>
  <div>
    <BravoBusinessesBanner @searched="onSearched" v-if="useBravoHero" class="pb-3" />
    <v-sheet class="mb-3" v-else>
      <AlfaBusinessesBanner @searched="onSearched" />
    </v-sheet>
    <v-container>
      <v-row justify="center">
        <v-col
          class="flex-grow-1 flex-shrink-0"
          :style="$vuetify.breakpoint.mdAndUp ? `max-width: calc(100% - (300px + 2 * 12px))` : null"
        >
          <group-card class="mb-5" title="Ettevõtete kategooriad">
            <BusinessCategories
              show-trustmark
              @input="onCategorySelected"
              :active-category-ids="activeCategoryIds"
            />
          </group-card>

          <group-card id="business-listings" class="mb-5" title="Ehitussektori ettevõtted">
            <BusinessListings :store-state="$store.state.business" store-accessor="business/" />
          </group-card>

          <div class="mb-5">
            <SubscribeBusinessBanner />
          </div>
          <AllNews
            v-if="$store.state.afterMounted"
            class="mb-5"
            :col-sizes="{ lg: 4 }"
            title="Ehitussektori uudised"
          />
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          style="max-width: calc(300px + 2 * 12px)"
          class="pb-0"
        >
          <MainSidebar />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AlfaBusinessesBanner from "../components/banner/AlfaBusinessesBanner.vue";
import SubscribeBusinessBanner from "../components/SubscribeBusinessBanner";
import AllNews from "@/blocks/news/components/AllNews";
import BusinessListings from "../components/BusinessListings";
import BusinessCategories from "../components/categories/BusinessCategories";
import BravoBusinessesBanner from "../components/banner/BravoBusinessesBanner";
import MainSidebar from "@/blocks/global/components/MainSidebar";

export default {
  components: {
    MainSidebar,
    BravoBusinessesBanner,
    BusinessCategories,
    BusinessListings,
    AllNews,
    SubscribeBusinessBanner,
    AlfaBusinessesBanner,
  },

  head() {
    return this.$store.getters.content("businesses.head");
  },

  computed: {
    useBravoHero() {
      const val = this.$store.getters.content(["businesses", "use_bravo_hero"]);
      return !val || val === "0" ? false : true;
    },
    activeCategoryIds() {
      return this.$store.state.business.query.category_ids;
    },
  },
  methods: {
    onCategorySelected(category) {
      this.$store.commit("business/SET_QUERY", { category_ids: [category.id] });
      this.$store.dispatch("business/fetchFirstPageItems");
      this.$nextTick().then(
        this.$nextTick(() => {
          this.$vuetify.goTo("#business-listings");
        }),
      );
    },
    onSearched(searchTerm) {
      this.$store.commit("business/SET_QUERY", { search_term: searchTerm });
      this.$store.dispatch("business/fetchFirstPageItems");
      setTimeout(() => {
        this.$nextTick().then(
          this.$nextTick(() => {
            this.$vuetify.goTo("#business-listings");
          }),
        );
      }, 600);
    },
  },
};
</script>
