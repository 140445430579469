<template>
  <div class="d-flex image-gradient__wrap">
    <v-sheet>
      <v-img
        class="elevation-1 cursor-pointer"
        @click="$router.push({ name: 'Business', params: { business_slug: business.slug } })"
        width="200px"
        :max-width="$vuetify.breakpoint.xs ? '100px' : '200px'"
        :max-height="$vuetify.breakpoint.xs ? '70px' : '125px'"
        :aspect-ratio="16 / 9"
        :src="business.list_image"
      >
        <div class="image-gradient" />
        <base-btn
          v-if="business.is_featured && $vuetify.breakpoint.smAndUp"
          x-small
          v-bind="{ tile: true, depressed: true, dark: true }"
          readonly
          :ripple="false"
          color="yellow darken-3 mx-1"
          tag="div"
        >
          <v-icon x-small class="mr-1">mdi-star</v-icon>
          Esiletõstetud
        </base-btn>
        <v-avatar
          @click="$router.push({ name: 'Business', params: { business_slug: business.slug } })"
          class="position-absolute"
          style="bottom: 6px; left: 6px; z-index: 2"
          color="white"
          size="60"
        >
          <v-sheet color="white">
            <v-img max-width="54px" max-height="54px" contain :src="business.logo_avatar"></v-img>
          </v-sheet>
        </v-avatar>
        <!-- <base-card class="position-absolute" style="bottom: 6px; left: 6px" rounded>
          <v-img max-width="70px" max-height="50px" contain :src="business.logo_avatar"></v-img>
        </base-card> -->
      </v-img>
      <v-chip
        v-if="business.is_featured && $vuetify.breakpoint.xs"
        x-small
        v-bind="{ tile: true, depressed: true, dark: true }"
        readonly
        :ripple="false"
        color="yellow darken-3 px-1 text-uppercase"
        style="border-radius: 0; width: 100px"
        tag="div"
      >
        <v-icon x-small class="mr-1">mdi-star</v-icon>
        Esiletõstetud
      </v-chip>
    </v-sheet>
    <v-sheet class="ml-2 flex-grow-1" :color="colored ? '#EEF9F8' : '#fff'">
      <div class="d-flex align-center h-100">
        <div class="h-100 pl-2 my-1 flex-grow-1 d-flex align-center">
          <div>
            <router-link
              class="link"
              :to="{ name: 'Business', params: { business_slug: business.slug } }"
            >
              <h4 class="text-h6">
                {{ business.title }}
              </h4>
            </router-link>
            <div
              class="cursor-pointer"
              @click="$router.push({ name: 'Business', params: { business_slug: business.slug } })"
            >
              <v-clamp
                autoresize
                class="text-body-2 mt-1 mb-2"
                tag="p"
                :max-lines="3"
                style="max-width: 500px"
              >
                {{ business.excerpt }}
              </v-clamp>
            </div>
            <div>
              <v-chip
                small
                label
                class="mr-2"
                v-for="tag in business.tags"
                :key="business.id + tag.slug"
              >
                {{ tag.name }}
              </v-chip>
            </div>
          </div>
        </div>

        <div class="h-100 d-flex flex-column align-end justify-end">
          <BookmarkBusiness :business="business" />

          <v-img
            max-width="110px"
            max-height="50px"
            src="/storage/base/trust-logo/ehitussektor-soovitab-2022.png"
            contain
            @click="$router.push($store.getters.setting('trustmark_path'))"
            class="mt-auto mb-0 mr-1 cursor-pointer"
          ></v-img>
        </div>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import BookmarkBusiness from "../BookmarkBusiness";
export default {
  components: {
    BookmarkBusiness,
  },
  props: {
    colored: {
      type: Boolean,
      default: false,
    },
    business: {
      type: Object,
      required: true,
    },
  },
};
</script>
