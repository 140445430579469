<style lang="scss">
.businesses-grid__col {
  @include media-breakpoint-up(sm) {
    max-width: 320px;
  }
}
</style>

<template>
  <v-container>
    <v-row justify="start">
      <v-col
        class="businesses-grid__col"
        cols="12"
        sm="6"
        md="4"
        v-for="(business, i) in businesses"
        :key="'grid-' + business.id + '-' + i"
      >
        <BusinessGridItem class="my-2" :business="business" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BusinessGridItem from "./business-item/BusinessGridItem";
export default {
  components: {
    BusinessGridItem,
  },
  props: {
    businesses: {
      type: Array,
      required: true,
    },
  },
};
</script>
