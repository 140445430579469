<template>
  <div>
    <div>
      <v-sheet class="position-relative" dark :height="isMobile ? 500 : 500" tile>
        <transition name="fade-slow" mode="in-out">
          <template v-for="(image, i) in images">
            <v-overlay
              v-if="i === currentImageIndex"
              :key="i + '-img'"
              :opacity="image.overlay_opacity ? parseInt(image.overlay_opacity) / 100 : 0"
              absolute
              :z-index="0"
              :style="
                image.bg_image
                  ? `background:url(&quot;` +
                    image.bg_image +
                    `&quot;) no-repeat scroll center center;background-color:` +
                    (image.bg_color ? image.bg_color : '#071b24')
                  : 'background-color:' + (image.bg_color ? image.bg_color : '#071b24')
              "
            ></v-overlay>
          </template>
        </transition>
        <div :style="'z-index: 1;'" class="h-100 position- d-flex align-center justify-center">
          <v-container class="businesses-banner" :key="isMobile ? 'mobile' : 'desktop'">
            <v-row>
              <v-col cols="12">
                <div class="position-relative">
                  <div class="d-flex justify-center justify-md-space-around h-100">
                    <div
                      :style="!isMobile ? `margin-top: ${firstImageWidth / 2}px` : ''"
                      class="mr-md-5 flex-shrink-1"
                    >
                      <BusinessesBannerSearch light @searched="$emit('searched', $event)" />
                    </div>
                    <div
                      class="d-flex justify-end flex-shrink-1"
                      style="opacity: 0"
                      v-if="!isMobile"
                    >
                      <base-card
                        elevation="6"
                        class="mx-3 mb-8"
                        style="margin-top: 60px"
                        :width="firstImageWidth"
                        :height="firstImageHeight"
                      >
                        <v-img
                          :src="image('cover_image_left')"
                          :max-width="firstImageWidth"
                          :height="firstImageHeight"
                        ></v-img>
                      </base-card>

                      <div>
                        <base-card elevation="3" class="ml-3 mr-1">
                          <v-img
                            :src="image('cover_image_right')"
                            :max-width="secondImageWidth"
                            :height="secondImageHeight"
                          ></v-img>
                        </base-card>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import BusinessesBannerSearch from "./BusinessesBannerSearch";
export default {
  components: {
    BusinessesBannerSearch,
  },

  mounted() {
    let timeout = parseInt(this.$store.getters.content(["businesses", "timer_interval"]));

    if (!timeout || isNaN(timeout)) {
      timeout = 7000;
    } else if (timeout < 2000) {
      timeout = 2000;
    }

    if (this.images.length > 1) {
      this.interval = setInterval(() => {
        this.toggleImageSrc();
      }, timeout);
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },

  data() {
    return {
      interval: null,
      currentImageIndex: 0,
      active: 1,

    };
  },

  computed: {
    images() {
      const items = this.$store.getters.content(["businesses", "page_hero"]);

      if (!items || !Array.isArray(items)) return [];
      return items.filter((item) => {
        if (this.$vuetify.breakpoint.xs && item.small_screen) {
          return true;
        } else if (
          (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) &&
          item.medium_screen
        ) {
          return true;
        } else if (this.$vuetify.breakpoint.lgAndUp && item.large_screen) {
          return true;
        }

        return false;
      });
    },
    bannerCssVars() {
      const styles = {
        "--bg-gradient-deg": 6 + "deg",
        "--bg-gradient-percent-1": 5 + "%",
        "--bg-gradient-percent-2": 20 + "%",
      };
      return styles;
    },

    imageSizer() {
      if (this.$vuetify.breakpoint.width < 1020) return 0.6;
      if (this.$vuetify.breakpoint.md) return 0.7;
      return 0.85;
      //   if (this.$vuetify.breakpoint.lg) return 0.85;
      //   return 1;
    },
    firstImageWidth() {
      return 400 * this.imageSizer;
    },
    firstImageHeight() {
      return 500 * this.imageSizer;
    },
    secondImageWidth() {
      return this.firstImageWidth * 0.9;
    },
    secondImageHeight() {
      return this.firstImageHeight * 0.9;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    toggleImageSrc() {
      if (this.currentImageIndex === this.images.length - 1) {
        this.currentImageIndex = 0;
        return;
      }
      this.currentImageIndex = this.currentImageIndex + 1;
    },
    image(type) {
      return "/storage/" + this.$store.getters.content(["businesses", type]);
    },
  },
};
</script>
