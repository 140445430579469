<template>
  <div class="business page">
    <NotFound v-if="mixinBusinessNotFound"></NotFound>
    <ShowBusiness v-else-if="business" :business="business" />
  </div>
</template>

<script>
import ShowBusiness from "@/blocks/business/components/ShowBusiness.vue";
import NotFound from "@/blocks/not-found/views/NotFound";
import fetchBusinessMixin from "@/blocks/business/fetchBusinessMixin";

export default {
  mixins: [fetchBusinessMixin],

  components: {
    NotFound,
    ShowBusiness,
  },

  head() {
    return this.mixinLoadingBusiness ? {} : this.business.head;
  },
};
</script>
