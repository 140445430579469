<style lang="scss">
@import "https://api.tiles.mapbox.com/mapbox-gl-js/v0.53.0/mapbox-gl.css";
</style>
<template>
  <MglMap
    class="transition-opacity"
    :class="loaded ? '' : 'opacity-0'"
    :accessToken="accessToken"
    :mapStyle="mapStyle"
    :center="computedCoordintes"
    :zoom="zoom"
    @load="loaded = true"
    style="min-height: 360px"
  >
    <MglMarker :coordinates="computedCoordintes" color="#B71C1C">
      <MglPopup showed :offset="15">
        <slot name="popup"></slot>
      </MglPopup>
    </MglMarker>
    <MglScaleControl position="top-right" />
    <MglNavigationControl position="top-right" />
    <MglGeolocateControl position="top-right" />
  </MglMap>
</template>

<script>
import Mapbox from "mapbox-gl";
import {
  MglMap,
  MglMarker,
  MglAttributionControl,
  MglNavigationControl,
  MglGeolocateControl,
  MglFullscreenControl,
  MglScaleControl,
  MglPopup,
} from "vue-mapbox";

export default {
  components: {
    MglMap,
    MglMarker,
    MglAttributionControl,
    MglNavigationControl,
    MglGeolocateControl,
    MglFullscreenControl,
    MglScaleControl,
    MglPopup,
  },
  created() {
    this.mapbox = Mapbox;
  },
  props: {
    coordinates: {
      type: Array,
      required: true,
    },
  },
  computed: {
    computedCoordintes() {
      /* Mapbox uses format [longitude, latitude], however google and everyone else uses [latitude, longitude], so we normalize by switching these. */
      return [this.coordinates[1], this.coordinates[0]];
    },
  },
  data() {
    return {
      loaded: false,
      zoom: 15,
      accessToken:
        "pk.eyJ1IjoicGludGVrIiwiYSI6ImNraXptOTYwNTJpYTYyd3NjemhybGlhcWIifQ.uAVcZvtmpPSgd9cew9XlLg",
      mapStyle: "mapbox://styles/mapbox/streets-v11",
    };
  },
};
</script>

<style>
button.mapboxgl-popup-close-button:focus {
  outline: none !important;
  size: 1rem !important;
}
</style>
