<style lang="scss">
</style>

<template>
  <v-row tag="section" id="business-offerings" justify="center">
    <v-col cols="12" class="mt-10 text-center">
      <h2 class="text-h5 font-weight-regular text-uppercase">Tooted ja teenused</h2>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
      v-for="offering in business.offerings"
      :key="offering.id"
      class="flex-grow d-flex justify-center"
    >
      <base-card elevation="0">
        <v-img v-if="offering.image" width="100%" height="200px" :src="offering.image"></v-img>
        <div class="d-flex flex-column jusstify-space-between" style="height: calc(100% - 200px)">
          <v-card-title>
            <div v-html="offering.title"></div>
          </v-card-title>

          <v-card-text class="text-body-1">
            <div v-html="offering.description"></div>
          </v-card-text>

          <div v-if="offering.external_link" class="mt-auto pb-4 pl-4">
            <base-btn
              target="_blank"
              :href="offering.external_link"
              outlined
              small
              color="grey darken-1"
              dark
              :title="`Teid suunatakse ${business.title} kodulehele`"
            >
              <v-icon small left>mdi-link-variant</v-icon>
              <span>Loe lähemalt</span>
            </base-btn>
          </div>
        </div>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
};
</script>
