<style lang="scss">
</style>

<template>
  <v-row tag="section" id="business-offerings" justify="center">
    <v-col cols="12" class="mt-10 text-center">
      <h2 class="text-h5 font-weight-regular text-uppercase">Tööpakkumised</h2>
    </v-col>
    <v-col>
      <v-sheet class="pa-4">
        <PublicJoblistingsList :wrap-details-chips="true" :items="business.joblistings" />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import PublicJoblistingsList from "../../jobs/joblisting/components/show/PublicJoblistingsList.vue";

export default {
  components: {
    PublicJoblistingsList,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
};
</script>
