<style lang="scss">
</style>

<template>
  <group-card :title="title" v-bind="$attrs">
    <v-container class="pa-0">
      <v-row class="my-0">
        <v-col v-bind="computedColSizes" v-for="post in posts" :key="post.id">
          <CharlieNewsPostItem :post="post" :height="200" small :show-category="showCategory" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" class="d-flex justify-center">
          <div class="mb-5" :title="allLoaded ? 'Rohkem artikleid ei ole' : 'Lae rohkem artikleid'">
            <base-btn
              color="black"
              :dark="!allLoaded"
              :loading="loading"
              @click="loadMoreArticles"
              :disabled="allLoaded"
            >
              Lae rohkem artikleid
            </base-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </group-card>
</template>

<script>
import CharlieNewsPostItem from "./news-post-item/CharlieNewsPostItem";
const defaultColSizes = { cols: 12, sm: 6, md: 4, lg: 3 };
import cacheMixin from "@/mixins/cacheMixin";
export default {
  mixins: [cacheMixin],
  components: {
    CharlieNewsPostItem,
  },
  props: {
    category: {
      type: Object,
      default: null,
    },
    excludedIds: {
      type: Array,
      default: () => [],
    },
    colSizes: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "Kõik artiklid",
    },
    showCategory: {
      type: Boolean,
      default: true,
    },
  },

  created() {
    if (this.category) this.query.post_category_id = this.category.id;

    this.pullCache(["posts", "query", "total", "loadedPage"], "AllNews" + this.$route.path);
    this.fetch();
  },
  destroyed() {
    this.saveCache();
  },

  data() {
    return {
      loading: null,
      posts: [],
      query: {
        page: 1,
        per_page: 12,
        category_id: null,
        order_by: "date",
        order_desc: true,
        excluded_ids: this.excludedIds,
      },
      total: null,
      loadedPage: null,
    };
  },
  computed: {
    computedColSizes() {
      return { ...defaultColSizes, ...this.colSizes };
    },
    allLoaded() {
      if (this.total === null) return null;
      return this.posts.length >= this.total;
    },
  },
  methods: {
    async fetch() {
      if (this.loadedPage === this.query.page) return;

      this.loading = true;
      try {
        const response = await this.axios.post("post/query", this.query);
        this.posts = [...this.posts, ...response.data.data];
        this.query = response.data.query;
        this.total = response.data.meta.total;
        this.loadedPage = this.query.page;
      } finally {
        this.loading = false;
      }
    },
    loadMoreArticles() {
      if (this.loading) return;
      this.query.page++;
      this.fetch();
    },
  },
};
</script>
