<style lang="scss">
.business-map .marker-content {
  p {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>

<template>
  <div class="business-map">
    <MapboxMap :coordinates="business.map_coordinates">
      <template #popup>
        <div>
          <h5 class="text-h6">{{ business.title }}</h5>
          <div class="marker-content" v-html="business.map_popup_content"></div>
          <ShowDirectionsBtn class="mt-0" :coordinates="business.map_coordinates" />
        </div>
      </template>
    </MapboxMap>
  </div>
</template>

<script>
import ShowDirectionsBtn from "@/blocks/global/components/ShowDirectionsBtn";
import MapboxMap from "@/blocks/global/MapboxMap";
export default {
  components: {
    MapboxMap,
    ShowDirectionsBtn,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
};
</script>
