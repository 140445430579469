<style lang="scss">
.businesses-banner-search {
  margin-top: 80px;
  margin-bottom: 80px;
  @include media-breakpoint-up(md) {
    margin-top: initial;
    margin-bottom: initial;
  }

  h1 {
    font-size: 18px;
    @include media-breakpoint-up(md) {
      font-size: 22px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 28px;
    }
    .lead {
      font-size: 36px;

      @include media-breakpoint-up(md) {
        font-size: 48px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 48px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .search-text-field {
      font-size: 1.125rem;
      //   font-size: 1rem;
    }
  }
  .search-sheet {
    @include media-breakpoint-up(md) {
      width: 550px;
    }
  }
}
</style>

<template>
  <div class="businesses-banner-search">
    <div>
      <h1 class="alternative-headings-font" style="font-size: 36px" v-html="coverTitle"></h1>
      <p
        v-if="coverSubtitle"
        class="alternative-headings-font"
        style="font-size: 18px"
        v-html="coverSubtitle"
      ></p>
    </div>
    <div class="position-relative">
      <form class="position-md-absolute" style="top: 0; left: 0; z-index: 50">
        <v-sheet class="search-sheet mt-4 d-flex align-center elevation-gentle" outlined light>
          <BusinessSearchField
            ref="searchField"
            v-model="searchTerm"
            :disable-instant-search="disableInstantSearch"
          />

          <base-btn
            type="submit"
            @click.prevent="search()"
            color="accent"
            elevation="0"
            class="mr-2"
            :loading="searching"
          >
            <span class="d-none d-sm-block mr-2">Otsi</span>
            <v-icon>mdi-magnify</v-icon>
          </base-btn>
        </v-sheet>
        <div class="mx-1 mt-2 text-body-2">
          <strong class="disabled-text">Populaarsed:</strong>
          <span v-for="(popular, i) in populars" :key="popular">
            <a
              :href="`#?otsi=${popular}`"
              :title="`Otsi märksõna &quot;${popular}&quot; järgi`"
              :class="light ? 'link-white ml-1' : 'link'"
              @click.prevent="onSearchPopular(popular)"
            >
              {{ popular + (populars.length > i + 1 ? "," : "") }}
            </a>
          </span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BusinessSearchField from "../BusinessSearchField";
export default {
  components: {
    BusinessSearchField,
  },
  props: {
    light: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //   populars: ["moodulmaja", "siseviimistleja", "elektrik", "aiatööd"],
      searchTerm: "",
      searching: null,
      disableInstantSearch: false,
    };
  },
  computed: {
    populars() {
      return this.$store.state.business.popularSearchTerms;
    },
    coverTitle() {
      return this.parseText(
        "businesses.cover_title",
        "Tee valik parimate seast.<br>Milline töö vajab tegemist?",
      );
    },
    coverSubtitle() {
      return this.parseText("businesses.cover_subtitle", null);
    },
  },
  methods: {
    parseText(getter, defaultTo = "") {
      const text = this.$store.getters.content(getter);
      if (!text) return defaultTo;
      return this.$replaceAll(text, "\r\n", "<br>");
    },

    onSearchPopular(popular) {
      this.searchTerm = "";
      this.$refs.searchField.disableInstantSearch();
      for (let i = 0; i <= popular.length; i++) {
        setTimeout(() => {
          if (i === popular.length) {
            this.search();
            this.$refs.searchField.enableInstantSearch();
            return;
          }
          this.searchTerm += popular[i];
        }, i * 15);
      }
    },

    search() {
      this.searching = true;
      this.$emit("searched", this.searchTerm);
      setTimeout(() => {
        this.searching = false;
      }, 1000);
    },
  },
};
</script>
