<template>
  <v-container class="h-100">
    <v-row no-gutters class="d-flex justify-center my-10">
      <v-col cols="12" sm="10" md="9" lg="8">
        <v-card elevation="0" tile>
          <v-card-title>
            <div class="w-100">
              <h1 class="text-h4">{{ content("title") }}</h1>
              <v-divider class="mt-3"></v-divider>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="text-body-2" v-html="content('content')"></div>
          </v-card-text>
          <div v-if="showList">
            <group-card>
              <BusinessListings
                :store-state="$store.state.business.bookmarked"
                store-accessor="business/bookmarked/"
              />
            </group-card>
          </div>
          <div v-else class="pb-1">
            <v-alert tile type="info" class="mx-3 mb-3">
              <h3 class="text-h6">{{ content("please_authenticate") }}</h3>
            </v-alert>
            <AuthForm outlined class="ma-3"></AuthForm>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BusinessListings from "../components/BusinessListings";
import AuthForm from "@/blocks/auth/components/AuthForm";

export default {
  components: {
    AuthForm,
    BusinessListings,
  },

  head() {
    return this.content("head");
  },

  data() {
    return {
      showList: null,
    };
  },

  computed: {
    authUserId() {
      return this.$store.state.auth.user.id;
    },
  },

  watch: {
    authUserId: {
      handler: function () {
        this.handleAuthUser();
      },
      immediate: true,
    },
  },

  methods: {
    handleAuthUser() {
      this.showList = false;

      if (!this.authUserId) {
        return;
      }

      const query = {
        ...this.$store.state.business.bookmarked.query,
        page: 1,
        bookmarked_by: this.authUserId,
      };

      this.$store.commit("business/bookmarked/SET", {
        query,
        items: [],
        total: null,
        loadedPage: null,
      });

      this.showList = true;
    },

    content(key, defaultTo = "") {
      const data = this.$store.getters.content(["bookmarked_businesses", key]);
      return data ? data : defaultTo;
    },
  },
};
</script>
