<style lang="scss">
</style>

<template>
  <div>
    <base-btn
      icon
      @click="showAsGrid = false"
      v-bind="!showAsGrid ? activeProps : inactiveProps"
      class="mr-1"
      title="Näita nimekirjana"
    >
      <v-icon>mdi-view-list</v-icon>
    </base-btn>
    <base-btn
      icon
      @click="showAsGrid = true"
      v-bind="showAsGrid ? activeProps : inactiveProps"
      title="Näita galeriina"
    >
      <v-icon>mdi-view-module</v-icon>
    </base-btn>
  </div>
</template>

<script>
export default {
  props: {
    isGrid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAsGrid: this.isGrid,
      inactiveProps: {
        text: true,
      },
      activeProps: {
        color: "accent",
      },
    };
  },
  watch: {
    showAsGrid(isGrid) {
      this.$emit("input", isGrid);
    },
  },
};
</script>
