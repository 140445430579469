<style lang="scss">
</style>

<template>
  <base-card elevation="0">
    <v-card-title>
      <h3 class="text-h4">Kontaktandmed</h3>
    </v-card-title>
    <v-card-text>
      <v-row justify="center">
        <v-col tag="section" cols="12" class="" v-if="business.contact_us_description">
          <div class="text-body-1" v-html="business.contact_us_description"></div>
        </v-col>
        <v-col
          tag="section"
          cols="12"
          :sm="colSize"
          v-for="(block, i) in business.contact_us_blocks"
          :key="`block` + i"
        >
          <h5 class="text-subtitle-2">{{ block.title }}</h5>
          <v-divider class="mb-2" />
          <div class="text-body-1" v-html="block.description"></div>

          <!-- <component
            v-if="_.get(block, ['component']) === 'ShowDirectionsBtn'"
            :is="block.component"
            :coordinates="business.map_coordinates"
          /> -->
        </v-col>
        <v-col class="pt-0" v-if="business.external_links">
          <SocialIcons class="px-2" colored large :urls="business.external_links" />
        </v-col>
      </v-row>
    </v-card-text>
  </base-card>
</template>

<script>
import SocialIcons from "@/blocks/global/components/SocialIcons";
// import ShowDirectionsBtn from "@/blocks/global/components/ShowDirectionsBtn";
export default {
  components: {
    // ShowDirectionsBtn,
    SocialIcons,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  computed: {
    colSize() {
      const l = this.business.contact_us_blocks.length;
      if (l === 1) return 12;
      if (l === 2) return 6;
      return 4;
    },
  },
};
</script>
