<template>
  <div>
    <v-sheet class="mb-3">
      <BusinessBanner :business="business" />
    </v-sheet>
    <v-container v-if="business.final">
      <BusinessContent
        class="mt-md-5"
        v-if="hasContent"
        :has-contacts-area="hasContactsArea"
        :business="business"
      />

      <BusinessOfferings :business="business" v-if="_.get(business, ['offerings'], []).length" />

      <BusinessReferences :business="business" v-if="_.get(business, ['references'], []).length" />

      <BusinessNews :business="business" v-if="_.get(business, ['posts'], []).length" />

      <BusinessPublicJoblistings
        v-if="_.get(business, ['joblistings'], []).length"
        :business="business"
      />

      <v-col id="contact-us" cols="12" class="mt-12 text-center" v-if="hasContactsArea">
        <h2 class="text-h5 font-weight-regular text-uppercase">Võta ühendust</h2>
      </v-col>

      <v-row :dense="$vuetify.breakpoint.mdAndUp" class="mt-2 mb-10" justify="center">
        <v-col cols="12" md="6">
          <BusinessContactForm :business="business" v-if="business.email" />
        </v-col>
        <v-col cols="12" md="6" v-if="business.contact_image">
          <BusinessContactImage :business="business" />
        </v-col>
        <v-col cols="12" md="6" class="flex-grow" v-if="hasContactDetails">
          <BusinessContactDetails class="h-100" :business="business" />
        </v-col>
        <v-col cols="12" v-if="hasMapCoordinates" :md="business.contact_image ? 6 : 8">
          <BusinessMap :business="business" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import BusinessPublicJoblistings from "./BusinessPublicJoblistings.vue";
import BusinessMap from "@/blocks/business/components/BusinessMap";
import BusinessContactImage from "@/blocks/business/components/BusinessContactImage";
import BusinessContactForm from "@/blocks/business/components/BusinessContactForm";
import BusinessContactDetails from "@/blocks/business/components/BusinessContactDetails";
import BusinessNews from "@/blocks/business/components/BusinessNews";
import BusinessReferences from "@/blocks/business/components/BusinessReferences";
import BusinessOfferings from "@/blocks/business/components/BusinessOfferings";
import BusinessContent from "@/blocks/business/components/BusinessContent";
import BusinessBanner from "@/blocks/business/components/business-banner/BusinessBanner";

export default {
  components: {
    BusinessBanner,
    BusinessContent,
    BusinessOfferings,
    BusinessReferences,
    BusinessNews,
    BusinessContactDetails,
    BusinessContactForm,
    BusinessContactImage,
    BusinessMap,
    BusinessPublicJoblistings,
  },

  props: {
    business: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasContent() {
      return this.business.description ||
        this.business.categories.length ||
        this.business.tags.length ||
        this.business.intro_video_url ||
        this.business.intro_image
        ? true
        : false;
    },
    hasContactsArea() {
      return this.business.email ||
        this.business.contact_image ||
        this.hasContactDetails ||
        this.hasMapCoordinates
        ? true
        : false;
    },
    hasContactDetails() {
      if (!this.business.final) {
        return false;
      }
      return (
        this.business.contact_us_description ||
        this.business.contact_us_blocks.length ||
        this.business.external_links
      );
    },
    hasMapCoordinates() {
      if (!this.business.final) {
        return false;
      }
      return this.business.map_coordinates && this.business.map_coordinates.length === 2;
    },
  },
};
</script>
